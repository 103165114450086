<template>
  <div class="welcome-page fs14">
    <div
      v-if="params.tips && !params.isNewest"
      class="hint-update clth flex flex-justify-between flex-align-center plr15"
      @click="onNewest"
    >
      <span>{{ params.tips }}</span>
      <div class="flex flex-align-center">
        <span class="mr5">去查看</span>
        <img
          class="right-allow"
          src="@/assets/img/common/bottom_allow_active.svg"
        />
      </div>
    </div>
    <div class="page-content bdr5 plr15 pt9">
      <div v-if="params.title" class="pb9 cl29 fw500">
        {{ params.title }}
      </div>
      <div
        v-if="isEditable"
        class="content-input-box bgf pt15 pb30 pl12 pr12 relative"
      >
        <textarea
          v-model="writing"
          class="content-input"
          :show-confirm-bar="false"
          confirm-type="done"
          placeholder="在此输入内容"
          :maxlength="800"
        />
        <div class="limit-text">
          <div class="fs13 cl99">
            {{ (writing && writing.length) || 0 }}/800
          </div>
        </div>
      </div>
      <div
        v-else
        class="writing-read-only bgf pt15 pb30 pl12 pr12 relative wb ws"
      >
        {{ writing }}
      </div>
      <div
        v-if="mediaList.length || isEditable"
        class="content-media bgf pt12 plr12 bdt"
      >
        <MediaGride
          pageType="material"
          :isEdit="isEditable"
          :mediaList="mediaList"
          @updateMediaList="updateMediaList"
        />
      </div>
    </div>
    <div class="btn-box flex plr15 mt22">
      <button
        v-if="!isEditable"
        class="handle-btn flex-1 mr15"
        @click="isEditable = true"
      >
        编辑
      </button>
      <button
        v-else
        class="handle-def-btn flex-1 mr15"
        @click="modalVisible = true"
      >
        重置
      </button>
      <button class="handle-btn flex-1" @click="showPopup">
        {{ isEditable ? '保存并发送' : '发送' }}
      </button>
    </div>
    <!-- <uni-popup
      ref="shareCover"
      type="share"
      safe-area
      background-color="#fff"
      style="position: fixed; z-index: 99"
    >
      <div class="share-pop-sendItem pl70 pr70 pt50" @click.stop="">
        <div class="flex flex-justify-between pb50">
          <div class="share-item" @click="onHandle('customer')">
            <img
              class="share-item-icon block"
              :src="`@/assets/knowledge/customer.svg`"
              mode="widthFix"
            />
            <div class="fs24 lh34 cl17 pt10">群发至客户</div>
          </div>
          <div class="share-item" @click="onHandle('group')">
            <img
              class="share-item-icon block"
              :src="`@/assets/knowledge/customer_group.svg`"
              mode="widthFix"
            />
            <div class="fs24 lh34 cl17 pt10">群发至客户群</div>
          </div>
          <div class="share-item" @click="onHandle('circleFriends')">
            <img
              class="share-item-icon block"
              :src="`@/assets/knowledge/Moments.svg`"
              mode="widthFix"
            />
            <div class="fs24 lh34 cl17 pt10">发至朋友圈</div>
          </div>
        </div>
        <div class="cancel-btn cl17 tc fs28" @click="closePopup">取消</div>
      </div>
    </uni-popup> -->
    <ModalView
      v-model="modalVisible"
      async-close
      @confirm="getDataSource"
      @cancel="modalVisible = false"
    >
      <div class="cl32 cl29 fw500 pt30 pb50 tc">确认重置内容?</div>
    </ModalView>
  </div>
</template>

<script>
import MediaGride from '@/components/detail/mediaGride.vue'
// import { navigateTo } from '@/utils/router.js'
// import { getRouteParams } from '@/utils/router.js'
import ModalView from '@/components/modalView/index.vue'
import {
  getSaleScriptByMember,
  setSaleScriptByMember,
  addSendContent
} from '@/service/material.service.js'
import { sendMonitor, setBackSend } from '@/service/quickSend.service.js'
// import { getItem } from '@/utils/index.js'
import { Toast } from 'vant'
import {
  getContext,
  sendChatMessageAllType,
  getCurExternalContact,
  getCurExternalChat
} from '@/utils/nwechat.js'
import { formatType, formatSendParams } from '@/utils/sendMessage.js'
import { onMounted } from '@vue/runtime-core'
import { encodeParams, decodeParams } from '@/utils/router'

export default {
  name: 'material-edit',
  components: { ModalView, MediaGride },
  data() {
    return {
      params: {}, // 路由参数
      modalVisible: false, // 弹窗显示
      isEditable: true, // 是否可编辑
      dataSource: {}, // 数据源
      mediaList: [], // 附件列表
      writing: '', // 文案
      qw_external_userid: '', // 外部联系人userid (聊天工具栏才有)
      qw_chat_id: '' // 当前客户群的群ID (聊天工具栏才有)
    }
  },
  async mounted() {
    this.params = decodeParams(this.$route.query)
    const { isEdit } = this.params
    this.isEditable = Boolean(isEdit)

    Toast.loading({
      message: '加载中',
      duration: 0,
      forbidClick: true
    })
    await this.getEntryType()
    await this.getDataSource()
    Toast.clear()
  },
  methods: {
    // 显示群发类型弹窗
    showPopup() {
      const { mediaList, writing, qw_external_userid, qw_chat_id } = this
      if (!mediaList.length && !writing) return Toast('发送内容不可为空')
      // uni.showToast({
      //   title: '发送内容不可为空',
      //   icon: 'none'
      // })
      if (qw_external_userid || qw_chat_id) {
        this.onSend()
      } else {
        // this.$refs.shareCover.open()
      }
    },
    // 关闭群发类型弹窗
    closePopup() {
      // this.$refs.shareCover.close()
    },
    // 文案输入框change
    // changeStatus(e) {
    //   this.writing = e.detail.value ? e.detail.value.substring(0, 800) : ''
    // },
    // 选择群发类型
    // async onHandle(key) {
    //   const { isEditable, params } = this
    //   const { sale_script_id, content_key } = params
    //   const { qw_userid } = getItem('user_info')
    //   setTimeout(() => this.closePopup(), 300)

    //   const data = await this.setDataSource()
    //   const content_member_id = isEditable ? qw_userid : '0'
    //   if (key === 'group') {
    //     this.$router.push('/pagesTool/sendGuide/index', {
    //       pageType: key,
    //       materialList: data
    //     })
    //   } else {
    //     this.$router.push('/pagesTool/screenCustomer/index', {
    //       pageType: key,
    //       sale_script_id,
    //       content_key,
    //       content_member_id,
    //       materialList: data
    //     })
    //   }
    // },
    // 发送内容
    async setDataSource() {
      try {
        const { params, writing, mediaList, isEditable } = this
        const { sale_script_id, content_key } = params
        const body = {
          sale_script_id,
          content_key,
          content_arr: []
        }

        if (mediaList.length) body.content_arr = [...mediaList]
        if (writing) {
          body.content_arr.unshift({
            type: 'TEXT',
            content: writing,
            content_key
          })
        }
        body.content_arr = body.content_arr.map((item, index) => ({
          ...item,
          sort: body.content_arr.length - index
        }))

        if (isEditable) {
          await setSaleScriptByMember(body)
        }

        return Promise.resolve(body.content_arr)
      } catch (error) {
        return Promise.reject(error)
        console.log(error)
      }
    },
    // 获取内容数据
    async getDataSource() {
      const { params } = this
      const {
        sale_script_id,
        content_key,
        member_id,
        isNewest,
        map_idx_id
      } = params
      const body = {
        sale_script_id,
        content_key,
        map_idx_id,
        member_id: isNewest ? '0' : member_id
      }
      const { data = [], code } = await getSaleScriptByMember(body)
      if (this.modalVisible) this.modalVisible = false
      if (code == 200 && data.length) {
        let writing = ''
        let mediaList = []
        for (let i = 0; i < data.length; i++) {
          const item = data[i]
          if (item.type == 'TEXT' && item.content) {
            writing = `${writing}${item.content}`
          } else {
            mediaList.push(item)
          }
        }
        this.writing = writing.substring(0, 800)
        this.mediaList = mediaList
      }
    },
    // 更新附件
    updateMediaList(data = []) {
      this.mediaList = data
    },
    // 查看最新内容
    async onNewest() {
      //   this.$router.push({
      //     name: 'material-edit',
      //     params: encodeParams({
      //       ...this.params,
      //       isNewest: true,
      //       isEdit: false
      //     })
      //   })
      this.params.isNewest = true
      this.isEditable = false
      Toast.loading({
        message: '加载中',
        duration: 0,
        forbidClick: true
      })
      await this.getEntryType()
      await this.getDataSource()
      Toast.clear()
    },
    // 获取页面入口类型
    async getEntryType() {
      // this.qw_external_userid = 'wm1DICCQAAq9tj0yzhQu1uDVYbKoTnLg'
      const entry = await getContext()
      if (entry === 'single_chat_tools') {
        this.qw_external_userid = await getCurExternalContact()
      } else if (entry === 'group_chat_tools') {
        this.qw_chat_id = await getCurExternalChat()
      }
    },
    // 聊天工具栏发送内容包
    async onSend() {
      try {
        const { params, qw_external_userid, qw_chat_id } = this
        const { sale_script_id } = params
        const content_arr = await this.setDataSource()
        let status = false
        Toast.loading({
          message: '加载中',
          forbidClick: true,
          duration: 0
        })

        const share_data_id = await this.beforeSend()
        for (let i = 0; i < content_arr.length; i++) {
          let type = formatType(content_arr[i].type)
          let params = await formatSendParams(
            type,
            {
              ...content_arr[i],
              sale_script_id,
              customer_id: qw_external_userid,
              chart_id: qw_chat_id,
              source: 'sale_script'
            },
            share_data_id
          )
          console.log('发送参数:::', type, params)
          const { sendStatus } = await sendChatMessageAllType(
            type,
            params,
            false
          )
          if (sendStatus) status = true
        }
        Toast.clear()
        if (status) {
          Toast.success({ message: '发送成功!' })
          this.recordDynamics()
          this.recordSend(content_arr)
        }
      } catch (error) {
        console.log(error)
      }
    },
    // 发送前获取埋点id
    async beforeSend() {
      const { params, qw_external_userid } = this
      const { sale_script_id, content_key } = params

      if (!qw_external_userid) return ''
      const body = {
        payable_id: sale_script_id,
        type: 'sale_script',
        user_id: qw_external_userid,
        content_key
      }

      let { data } = await sendMonitor(body)
      return data?.share_data_id || ''
    },
    // 记录发送动态
    async recordDynamics() {
      const { params, qw_external_userid, qw_chat_id } = this
      const { sale_script_id, content_key } = params

      const body = {
        sale_script_id,
        content_key,
        field_value: qw_external_userid || qw_chat_id || '',
        event_key: '',
        field: ''
      }
      if (qw_external_userid) {
        body.event_key = 'customer_receive_sale_script'
        body.field = 'external_contact'
      } else if (qw_chat_id) {
        body.event_key = 'customer_touch_sale_script_group'
        body.field = 'chat_id'
      }

      await setBackSend(body)
    },
    // 好友聊天工具栏发送内容包
    async recordSend(data) {
      const { qw_external_userid, qw_chat_id, params } = this
      const { sale_script_id, content_key, status_tips } = params
      const userInfo = JSON.parse(localStorage.getItem('vuex')) || {}
      const content_member_id =
        String(status_tips) === '1' || String(status_tips) === '2'
          ? userInfo.user.info.qw_userid
          : '0'
      const body = {
        sale_script_id,
        content_arr: data,
        content_key,
        content_member_id,
        type: qw_external_userid ? '1' : '2',
        qw_user_chat_id: qw_external_userid || qw_chat_id
      }
      await addSendContent(body)
    }
  }
}
</script>

<style lang="less">
::-webkit-scrollbar {
  display: none;
  width: 0 !important;
  height: 0 !important;
  -webkit-appearance: none;
  background: transparent;
}
.pt9 {
  padding-top: 9px;
}
.pb9 {
  padding-bottom: 9px;
}
.pl12 {
  padding-left: 12px;
}
.pr12 {
  padding-right: 12px;
}
.pb18 {
  padding-bottom: 18px;
}
.mt22 {
  margin-top: 22px;
}
.right-allow {
  width: 16px;
  height: 16px;
  transform: rotate(-90deg);
}
.welcome-page {
  padding-bottom: calc(15px + env(safe-area-inset-bottom) / 2);
}
.hint-update {
  height: 40px;
  background: rgba(2, 157, 157, 0.1);
}
.page-content {
  overflow: hidden;

  .content-input-box {
    border-radius: 5px 5px 0 0;
  }
  .content-input {
    width: 100%;
    height: 100px;
    border: none;
    resize: none;
  }

  .writing-read-only {
    min-height: 150px;
    border-radius: 5px 5px 0 0;
  }
  .content-media {
    border-radius: 0 0 5px 5px;
  }
  .limit-text {
    position: absolute;
    bottom: 9px;
    right: 12px;
    color: #999;
    font-size: 13px;
  }
}

.share-pop-sendItem {
  border-radius: 10px;

  .share-item-icon {
    width: 42px;
    height: 42px;
    margin: 0 auto;
  }

  .cancel-btn {
    line-height: 40px;
  }
}
</style>
